import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import Container from "../components/Container";
import PageContent from "../components/PageContent";
import { useTheme } from "../components/ThemeContext";

export default function AffiliateDisclaimer() {
  const { theme } = useTheme();
  const { title } = theme;
  return (
    <Layout>
      <SEO
        title="Affiliate disclaimer"
        description={`${theme.url} strives to provide great content for it's readers. We are, however, an affiliate site. If you are unaware of what that means, click here to read our overview.`}
      />
      <Container>
        <PageContent>
          <h1>Affiliate Disclaimer</h1>
          <p>
            While {title} is all about producing high-quality content, this site is not a non-profit entity. It is a business with
            expenses and revenue.
          </p>
          <p>
            One way this site earns revenue in return for publishing content, is by linking to suggested products and services
            with affiliate links.
          </p>
          <p>
            These links track purchases on third party websites and we may get a commission upon a purchase. The value of this
            commission may be a lot or not much at all.
          </p>
          <p>
            We strive to be transparent, and as such, it&apos;s a core value of our business to let you know that we are getting
            paid. In fact, it’s actually a law to inform you that we are being compensated for our content in this manner.
          </p>
          <p>
            Finally, we strive our hardest to put all recommendations through a rigorous editorial process, however, we cannot
            guarantee that you&apos;ll like or see the same value in the products we link to from this site. Therefore, please do
            your own diligence before purchasing anything.
          </p>
        </PageContent>
      </Container>
    </Layout>
  );
}
